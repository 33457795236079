import { COLORS } from 'assets/styles/colors';
import { SIZE } from 'assets/styles/devices';
import { TYPOGRAPHY } from 'assets/styles/typography';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/route-paths';
import styled from 'styled-components';
import { Icon, Logo } from 'ui';
import { FirmwareUpdateModal } from 'ui/FirmwareUpdateModal';
import { SwipeableBottomDrawer } from 'ui/SwipeableBottomDrawer';

import { ChildLockCartridgeControl } from './components/ChildLockCartridgeControl';
import { ChildLockTimerControl } from './components/ChildLockTimerControl';
import { MenuButton } from './components/MenuButton/MenuButton';
import { MenuLink } from './components/MenuLink/MenuLink';

// import { OverPuffControl } from './components/OverPuffControl';

type MenuProps = {
  isOpen: boolean;
  onExitClick: () => void;
  onAddNewPlonqClick: () => void;
  closeCallBack: () => void;
  deviceData: {
    deviceId: string;
    deviceVersion?: string;
    firmwareVersion?: string;
    MACAddress?: string;
    activatedAt?: string;
  };
};

export const Menu = ({
  isOpen,
  deviceData,
  closeCallBack,
  onAddNewPlonqClick,
  onExitClick,
}: MenuProps) => {
  const navigate = useNavigate();
  const [isFirmwareUpdateModalOpen, setFirmwareUpdateModalOpen] =
    useState(false);

  return (
    <>
      <SwipeableBottomDrawer isOpen={isOpen} onCloseCallback={closeCallBack}>
        <ContentWrapper>
          <Logo width="135px" />
          <DeviceInfo>
            <DeviceInfoText>
              Версия устройства: {deviceData.deviceVersion}
            </DeviceInfoText>
            <DeviceInfoText>
              Версия прошивки: {deviceData.firmwareVersion}
            </DeviceInfoText>
            <DeviceInfoText>MAC адрес: {deviceData.MACAddress}</DeviceInfoText>
            <DeviceInfoText>
              Дата активации:{' '}
              {dayjs(deviceData.activatedAt).format('DD.MM.YYYY')}
            </DeviceInfoText>
          </DeviceInfo>
          <StyledExtraFunctionsControl>
            <ChildLockTimerControl isEnabled />
            <ChildLockCartridgeControl isEnabled />
            {/*<OverPuffControl />*/}
          </StyledExtraFunctionsControl>
          <MenuButton
            onClick={() => setFirmwareUpdateModalOpen(true)}
            text="Обновление ПО"
            icon={<Icon type="updateSoftware" />}
          />
          <MenuButton
            onClick={onAddNewPlonqClick}
            text="Подключить новый Plonq X"
            icon={<Icon type="device" />}
          />
          <BottomLinksWrapper>
            <MenuLink
              address="https://plonq.ru/"
              text="Сайт"
              icon={<Icon type="site" />}
              isTextUnder
            />
            <MenuLink
              address="https://plonq.ru/salesmap/"
              text="Магазины"
              icon={<Icon type="store" />}
              isTextUnder
            />
            <MenuButton
              onClick={() => {
                closeCallBack();
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                jivo_api.open();
              }}
              text="Поддержка"
              icon={<Icon type="support" />}
              isTextUnder
            />
            <MenuButton
              onClick={onExitClick}
              text="Выход"
              icon={<Icon type="logout" />}
              isTextUnder
            />
          </BottomLinksWrapper>
        </ContentWrapper>
      </SwipeableBottomDrawer>
      {isFirmwareUpdateModalOpen && (
        <FirmwareUpdateModal
          deviceId={deviceData.deviceId}
          currentVersion={deviceData.firmwareVersion || '0.0.0'}
          onClose={() => setFirmwareUpdateModalOpen(false)}
          onSubmit={() =>
            navigate(ROUTES.UPDATED_DEVICE_ID(deviceData.deviceId))
          }
        />
      )}
    </>
  );
};

const ContentWrapper = styled.div`
  padding: 52px 32px 22px 32px;

  @media (max-width: ${SIZE.mobileM}) {
    padding: 52px 28px 22px 28px;
  }
`;

const DeviceInfo = styled.div`
  margin: 31px 0 32px 0;
  display: flex;
  flex-direction: column;
  row-gap: 11px;
`;

const DeviceInfoText = styled.p`
  ${TYPOGRAPHY.body1}
  color: ${COLORS.darkBlue};
`;

const BottomLinksWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 32px;
`;

const StyledExtraFunctionsControl = styled.div`
  margin-bottom: 30px;
`;
