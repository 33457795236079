import { CompareVersion } from 'lib/helpers/compareVersions';
import { debounce } from 'lodash';

import {
  clearPuffArrayCommand,
  getPuffArrayCommand,
  puffPackageResponse,
} from '../bluetooth/commands';
import { VERSION_WITH_PACKAGE_GPA } from '../constants';
import { IPuff } from '../interfaces';
import { BLEConnectionNotInstalled } from './exception/ble-connection-not-installed';
import { PlonqDevice } from './plonq-device';
import { PlonqNordicConnectedService } from './plonq-nordic-connected-service';

type UpdateHandler = (device: PlonqPuffArray) => void;

/**
 * Класс отвечает за реализацию работы со списком записей о затяжках.
 * Для работы необходим подключенный экземпляр PlonqDevice.
 *
 * На устройстве записи о затяжках хранятся с неправильным таймстемпом,
 * поэтому класс хранит в себе timestampOffset. timestampOffset вычисляется
 * на этапе первичной синхронизации в классе {@link PlonqDevice}
 *
 * Содержит несколько методов синхронизации затяжек, тк механизм
 * отличается для разных версий прошивок.
 *
 * @deprecated
 * Для обновления данных во время подключения используется подписка
 * на характеристику puffCounter.
 *
 * @updated
 * Для обновления данных во время подключения используется long pulling
 * записей о затяжках раз в 3 секунды. Очищение устройства сделано через
 * debounced в 5 секунд. Для работы добавлено поле lastSyncCount для выгрузки
 * только нужных записей внутри цикла debounce. После каждой выгрузки полученное
 * количество записей(puffCount) сравнивается с lastSyncCount. В случае если записей больше lastSyncCount
 * выгружаются записи с lastSyncCount + 1 по puffCount
 *
 * Класс хранит в себе кэшированный массив записей о затяжках и
 * предоставляет его клиентскому коду. При добавлении новой записи в
 * массив класс уведомляет всех подписантов на это событие.
 *
 * Основное место использование класса хук {@link usePuffUpload}.
 *
 * Для интеграции c React компонентами нужно обернуть в хук.
 *
 * Используется в хуке {@link usePuffArrayUpload}
 */
export class PlonqPuffArray extends PlonqNordicConnectedService {
  private puffArray: IPuff[] = [];
  private pullTimer?: NodeJS.Timeout;
  private static instance?: PlonqPuffArray;
  private timestampOffset = 0;
  private lastSyncCount = 0;

  // Для создания необходимо чтобы PlonqDevice был инициализирован
  private constructor() {
    super();

    // Необходимо для корректировки UNIX time установленного на устройстве
    this.timestampOffset = PlonqDevice.getInstance().getTimestampOffset();
  }

  public static getInstance(): PlonqPuffArray {
    if (this.instance) {
      return this.instance;
    }

    this.instance = new PlonqPuffArray();

    return this.instance;
  }

  public getPuffArrayLength(): number {
    return this.puffArray.length;
  }

  public getPuffArray(from?: number, to?: number): IPuff[] {
    return this.puffArray.slice(from, to);
  }

  public deletePuffs(index = 0) {
    this.puffArray = this.puffArray.slice(index);
  }

  public async clearFromDevice(): Promise<void> {
    if (!this.bleConnection) {
      throw new BLEConnectionNotInstalled();
    }

    console.log('clear device');
    await this.bleConnection.sendRequest(clearPuffArrayCommand(), true);
    this.lastSyncCount = 0;
  }

  private clearPuffArrayData = (): void => {
    console.log('[CLEAR_PUFF_ARRAY]: Cleaning up due to disconnect');
    if (this.pullTimer) {
      clearTimeout(this.pullTimer);
      this.pullTimer = undefined;
    }

    PlonqPuffArray.instance = undefined;
    this.isConnected = false;
    this.bleConnection = undefined;
    this.puffArray = [];
    this.timestampOffset = 0;
  };

  public async syncWithBLE(): Promise<void> {
    console.log('Sync start');
    if (!this.bleConnection) {
      throw new BLEConnectionNotInstalled();
    }

    this.bleConnection.onDisconnect(this.clearPuffArrayData.bind(this));

    console.log('Sync end');

    if (
      // Проверка на наличие package GPA
      CompareVersion(
        PlonqDevice.getInstance().getFirmware(),
        VERSION_WITH_PACKAGE_GPA,
      )
    ) {
      console.log('skip sync. (cause: firmware version is not actual)');
      return;
    }

    setTimeout(this.updatePuffArray.bind(this), 3000);
  }

  debouncedDeviceClear = debounce(this.clearFromDevice.bind(this), 5000);

  private async updatePuffArray(): Promise<void> {
    try {
      console.log('[PULLING_PUFFS]: start');

      if (!this.bleConnection || !this.isConnected) {
        console.log('[PULLING_PUFFS]: stopped due to disconnection');
        return;
      }

      const puffCount = await this.bleConnection.getPuffCount();
      console.log(
        `[PULLING_PUFFS]: count ${puffCount}, last sync puff ${this.lastSyncCount}`,
      );

      if (puffCount > this.lastSyncCount) {
        console.log(`[PULLING_PUFFS]: pulling puffs`);
        const puffPackageBinaries = (await this.bleConnection.sendRequest(
          getPuffArrayCommand(this.lastSyncCount + 1, puffCount),
        )) as DataView[];

        if (!puffPackageBinaries || puffPackageBinaries.length === 0) {
          console.log('[PULLING_PUFFS]: no new puff data received');
          return;
        }

        console.log(
          `[PULLING_PUFFS]: pulled ${puffPackageBinaries.length} puff packages`,
        );

        for (const puffPackageBinary of puffPackageBinaries) {
          const puffsPackage = puffPackageResponse(puffPackageBinary);
          console.log('[PUFFS_PACKAGE]:', puffsPackage);
          this.puffArray = this.puffArray.concat(
            puffsPackage.map((puff) => ({
              ...puff,
              timestamp: Math.min(
                puff.timestamp + this.timestampOffset,
                Date.now(),
              ),
            })),
          );
        }

        this.lastSyncCount = puffCount;
        this.debouncedDeviceClear();
      } else {
        console.log('[PULLING_PUFFS]: no new puffs');
      }

      console.log('[PULLING_PUFFS]: end');
    } catch (err) {
      console.log('[PULLING_PUFFS]: error', err);

      if ((err as Error).message.includes('GATT Server is disconnected')) {
        console.log('[PULLING_PUFFS]: attempting reconnection...');
        await this.getBleConnection()?.refreshConnection();
      }
    } finally {
      if (!this.isConnected) {
        console.log('[PULLING_PUFFS]: stopping timer due to disconnection');
        if (this.pullTimer) clearTimeout(this.pullTimer);
        this.pullTimer = undefined;
      } else {
        if (this.pullTimer) clearTimeout(this.pullTimer);
        this.pullTimer = setTimeout(() => this.updatePuffArray(), 3000);
      }
    }
  }
}
