import { CompareVersion } from 'lib/helpers/compareVersions';
import { VERSION_WITH_CHILD_LOCK } from 'lib/services/constants';
import { useCallback, useMemo } from 'react';

import { ChildLockSettings, PlonqChildLock } from '../plonq-child-lock';
import { PlonqDevice } from '../plonq-device';

/**
 * Хук для адаптации логики управления настройками ChildLock для UI компонентов.
 * Использует экземпляр класса {@link PlonqChildLock} для доступа к устройству.
 *
 * @example
 * const [{ getSettings, setSettings }] = useChildLockSettings();
 *
 * const settings = await getSettings();
 * const settingsAfterUpdate = await setSettings({ time: 300, cartridge: false });
 *
 * @returns объект с двумя функциями для установки и получения настроек ChildLock.
 */
const useChildLockSettings = () => {
  const setChildLockSettings = useCallback((settings: ChildLockSettings) => {
    return PlonqChildLock.getInstance().setChildLockSettings(settings);
  }, []);

  const getChildLockSettings = useCallback(() => {
    return PlonqChildLock.getInstance().getChildLockSettings();
  }, []);

  const hasChildLock = useMemo(() => {
    try {
      return !CompareVersion(
        PlonqDevice.getInstance().getFirmware(),
        VERSION_WITH_CHILD_LOCK,
      );
    } catch (err) {
      console.error(err);
      return false;
    }
  }, []);

  return [
    { getSettings: getChildLockSettings, setSettings: setChildLockSettings },
    hasChildLock,
  ] as const;
};

export default useChildLockSettings;
