import { wait } from 'lib/helpers';
import { CompareVersion } from 'lib/helpers/compareVersions';
import {
  BluetoothConnection,
  Handler,
} from 'lib/services/bluetooth/bluetooth-connection';
import {
  Characteristics,
  VERSION_WITH_BATTERY_CHARGING_TIME,
  VERSION_WITH_CHILD_LOCK,
  uuidsByCharacteristicMap,
} from 'lib/services/constants';

import {
  childLockSettingsResponse,
  childLockStatusResponse,
  convertByteToParamsChildLockSettings,
} from '../bluetooth/commands';
import { mergeHexCodes, toHexArray, toHexCode } from './utils';

/**
 * Класс отвечает за работу с характеристиками устройствами
 * предоставляемыми GATT Server'ом. Предоставляет доступ к основной
 * информации об устройстве.
 *
 * Подписывается на нотификации характеристик и предоставляет эту подписку
 * UI компонентам для динамического отображения характеристик устройства.
 *
 * Хранит в себе timestampOffset необходимый для вычисления актуального
 * времени записей о затяжках.
 *
 * timestamp внутри устройства итерируется неравномерно, с этим могут быть связанны
 * проблемы синхронизации записей затяжек, тк вычисляемое время изменяется.
 *
 * Используется во всех проверках на подключение устройства.
 *
 * Используется в хуке {@link useDeviceState} для предоставления
 * данных для UI компонентов.
 *
 * Наиболее важный класс для работы приложения. От него
 * зависят остальные сервисы по работе с устройством, тк
 * данный класс предоставляет доступ к {@link BluetoothConnection}.
 *
 * Должен инициализироваться раньше остальных сервисов.
 *
 * Для работы необходим подключенный экземпляр PlonqDevice.
 *
 * Отвечает за получение и установку ChildLock Settings.
 * Отдельно передается таймер и маска настроек.
 *
 * Для интеграции c React компонентами нужно обернуть в хук.
 */
export class PlonqDeviceConnection {
  private bluetoothConnection: BluetoothConnection;

  constructor(bluetoothConnection: BluetoothConnection) {
    this.bluetoothConnection = bluetoothConnection;
  }

  public getBluetoothConnection(): BluetoothConnection {
    return this.bluetoothConnection;
  }

  public async getFirmwareVersion(): Promise<string> {
    const characteristic = await this.getCharacteristic(
      Characteristics.FIRMWARE_VERSION,
    );
    const value = await characteristic.readValue();

    return this.parseDataToString(value);
  }

  public async getHardwareVersion(): Promise<string> {
    const characteristic = await this.getCharacteristic(
      Characteristics.HARDWARE_VERSION,
    );
    const value = await characteristic.readValue();

    return this.parseDataToString(value);
  }

  public async getModelName(): Promise<string> {
    const modelNameCharacteristic = await this.getCharacteristic(
      Characteristics.MODEL_NAME,
    );
    const manufacturerNameCharacteristic = await this.getCharacteristic(
      Characteristics.MANUFACTURER_NAME,
    );
    const modelNameValue = await modelNameCharacteristic.readValue();
    const manufactureNameValue =
      await manufacturerNameCharacteristic.readValue();

    const manufactureName = this.parseDataToString(manufactureNameValue);
    const modelName = this.parseDataToString(modelNameValue);

    return `${manufactureName} ${modelName}`;
  }

  public async getBatteryLevel(): Promise<number> {
    const characteristic = await this.getCharacteristic(
      Characteristics.BATTERY_LEVEL,
    );
    const value = await characteristic.readValue();

    return value.getUint8(0);
  }

  public async getBatteryChargingTime() {
    if (
      CompareVersion(
        await this.getFirmwareVersion(),
        VERSION_WITH_BATTERY_CHARGING_TIME,
      )
    ) {
      return 0;
    }

    const characteristic = this.getCharacteristic(Characteristics.CHARGING);

    const value = await characteristic.readValue();
    return value.getInt16(1, true);
  }

  public async getIsCharging(): Promise<boolean> {
    const characteristic = await this.getCharacteristic(
      Characteristics.CHARGING,
    );
    const value = await characteristic.readValue();

    return value.getUint8(0) === 1;
  }

  public async getMacAddress(): Promise<string> {
    const characteristic = await this.getCharacteristic(
      Characteristics.MAC_ADDRESS,
    );
    const value = await characteristic.readValue();

    const macArr = Array(6).fill(undefined);

    for (let i = 0; i < macArr.length; ++i) {
      const macPartStr = value.getUint8(i).toString(16);
      macArr[i] = macPartStr.length > 1 ? macPartStr : '0' + macPartStr;
    }

    return macArr.join(':').toLowerCase();
  }

  public async getCartridgeId(): Promise<number> {
    const characteristic = await this.getCharacteristic(
      Characteristics.CARTRIDGE_ID,
    );
    const value = await characteristic.readValue();

    return value.getUint8(0);
  }

  public async getChildLockSettings(): Promise<any> {
    const characterisic = await this.getCharacteristic(
      Characteristics.CHILD_LOCK_SETTINGS,
    );
    const dataView = await characterisic.readValue();

    const bytes = new Array(dataView.byteLength).fill(undefined);

    for (let i = 0; i < dataView.byteLength; ++i) {
      bytes[i] = toHexCode(dataView.getUint8(i));
    }

    const settings = convertByteToParamsChildLockSettings(
      Number.parseInt(bytes[1]),
    );
    const time = Number.parseInt(mergeHexCodes(bytes.slice(2, 4), true));

    return {
      ...settings,
      time,
    };
  }

  public async getCartridgeResistance(): Promise<number> {
    const characteristic = await this.getCharacteristic(
      Characteristics.CARTRIDGE_RESISTANCE,
    );

    const value = await characteristic.readValue();

    return value.getUint32(0, true);
  }

  public async getUnixTime(): Promise<number> {
    const characteristic = await this.getCharacteristic(
      Characteristics.UNIX_TIME,
    );
    const value = await characteristic.readValue();

    return value.getUint32(0, true);
  }

  public async setCurrentUnixTime(): Promise<void> {
    const characteristic = this.getCharacteristic(Characteristics.UNIX_TIME);
    const timestamp = Math.floor(new Date().getTime() / 1000);
    const [tMSB, t1, t2, tLSB] = toHexArray(timestamp, 4);

    await characteristic.writeValue(
      new Uint8Array([
        Number.parseInt(tLSB),
        Number.parseInt(t2),
        Number.parseInt(t1),
        Number.parseInt(tMSB),
      ]),
    );
  }

  public async getRSSI(): Promise<number> {
    const characteristic = await this.getCharacteristic(Characteristics.RSSI);
    const value = await characteristic.readValue();

    return value.getInt8(0);
  }

  public async getChildLock(): Promise<boolean> {
    if (
      CompareVersion(await this.getFirmwareVersion(), VERSION_WITH_CHILD_LOCK)
    ) {
      return false;
    }

    const characteristic = await this.getCharacteristic(
      Characteristics.CHILD_LOCK,
    );

    const value = await characteristic.readValue();

    return childLockStatusResponse(value.getInt8(0));
  }

  public async setChildLock(active: boolean): Promise<void> {
    if (
      CompareVersion(await this.getFirmwareVersion(), VERSION_WITH_CHILD_LOCK)
    ) {
      return;
    }

    const characteristic = await this.getCharacteristic(
      Characteristics.CHILD_LOCK,
    );

    await characteristic.writeValue(new Uint8Array([active ? 0x01 : 0x00]));
  }

  public async onCartridgeIdChange(
    handler: (id: number) => void,
  ): Promise<void> {
    // Необходимо для корректной работы на мобильных устройствах
    await wait(100);
    let characteristic = await this.getCharacteristic(
      Characteristics.CARTRIDGE_ID,
    );

    characteristic = await characteristic.startNotifications();
    characteristic.addEventListener(
      'characteristicvaluechanged',
      (event: any) => {
        if (event.target) {
          handler(event.target.value.getUint8(0));
        }
      },
    );
  }

  public async onChargingChange(handler: (id: boolean) => void): Promise<void> {
    // Необходимо для корректной работы на мобильных устройствах
    await wait(100);
    let characteristic = this.getCharacteristic(Characteristics.CHARGING);

    characteristic = await characteristic.startNotifications();
    characteristic.addEventListener(
      'characteristicvaluechanged',
      (event: any) => {
        if (event.target) {
          handler(event.target.value.getUint8(0) === 1);
        }
      },
    );
  }

  public async onBatteryLevelChange(
    handler: (id: number) => void,
  ): Promise<void> {
    let characteristic = this.getCharacteristic(Characteristics.BATTERY_LEVEL);

    characteristic = await characteristic.startNotifications();
    characteristic.addEventListener(
      'characteristicvaluechanged',
      (event: any) => {
        if (event.target) {
          handler(event.target.value.getUint8(0));
        }
      },
    );
  }

  public async onBatteryLifeTimeChange(
    handler: (id: number) => void,
  ): Promise<void> {
    if (
      CompareVersion(
        await this.getFirmwareVersion(),
        VERSION_WITH_BATTERY_CHARGING_TIME,
      )
    ) {
      return;
    }

    let characteristic = this.getCharacteristic(Characteristics.CHARGING);

    characteristic = await characteristic.startNotifications();
    characteristic.addEventListener(
      'characteristicvaluechanged',
      (event: any) => {
        if (event.target) {
          const timeToFullCharge = (event.target.value as DataView).getUint16(
            1,
            true,
          );
          handler(timeToFullCharge);
        }
      },
    );
  }

  public async onRSSIChange(handler: (id: number) => void): Promise<void> {
    // Необходимо для корректной работы на мобильных устройствах
    await wait(100);
    let characteristic = this.getCharacteristic(Characteristics.RSSI);

    characteristic = await characteristic.startNotifications();
    characteristic.addEventListener(
      'characteristicvaluechanged',
      (event: any) => {
        if (event.target) {
          handler(event.target.value.getInt8(0));
        }
      },
    );
  }

  public async onChildLockChange(
    handler: (isActive: boolean) => void,
  ): Promise<void> {
    if (
      CompareVersion(await this.getFirmwareVersion(), VERSION_WITH_CHILD_LOCK)
    ) {
      return;
    }

    let characteristic = this.getCharacteristic(Characteristics.CHILD_LOCK);

    characteristic = await characteristic.startNotifications();
    characteristic.addEventListener(
      'characteristicvaluechanged',
      (event: any) => {
        if (event.target) {
          handler(childLockStatusResponse(event.target.value.getUint8(0)));
        }
      },
    );
  }

  public async onChildLockSettingsChange(
    handler: (dataView: any) => void,
  ): Promise<void> {
    if (
      CompareVersion(await this.getFirmwareVersion(), VERSION_WITH_CHILD_LOCK)
    ) {
      return;
    }

    let characteristic = this.getCharacteristic(
      Characteristics.CHILD_LOCK_SETTINGS,
    );

    characteristic = await characteristic.startNotifications();
    characteristic.addEventListener(
      'characteristicvaluechanged',
      (event: any) => {
        if (event.target) {
          handler(childLockSettingsResponse(event.target.value));
        }
      },
    );
  }

  private getCharacteristic(
    characteristicName: Characteristics,
  ): BluetoothRemoteGATTCharacteristic {
    const connectionUUIDs = uuidsByCharacteristicMap[characteristicName];
    const characteristicId = connectionUUIDs[1];

    const characteristic =
      this.bluetoothConnection.getGATTCharacteristic(characteristicId);

    if (!characteristic) {
      throw new Error(`Cannot find ${characteristicName} characteristic`);
    }

    return characteristic;
  }

  public disconnect = (): void => {
    this.bluetoothConnection.getGATTGateway().disconnectGattServer();
  };

  private parseDataToString(buffer: DataView): string {
    let string = '';
    for (let i = 0; i < buffer.byteLength; ++i) {
      const a = buffer.getUint8(i);

      string += String.fromCharCode(a);
    }

    return string;
  }

  public onDisconnect(disconnectHandler: Handler): void {
    this.bluetoothConnection.onDisconnect(disconnectHandler);
  }

  public onReconnect(reconnectHandler: Handler): void {
    this.bluetoothConnection.onReconnect(reconnectHandler);
  }
}
